import React from 'react'

class GridCell extends React.Component {
    render() {
        const {letter, status} = this.props;

        return (
            <div className={`grid-cell ${status}`}>
                <h1>{letter}</h1>
            </div>
        );
    }
}

export default GridCell;