import React from 'react';

class KeyboardLetter extends React.Component {
    render() {
        const {receiveInput, letter, keyboard} = this.props;
        let id = "";
        if(letter === "enter") id = "enter-key";
        if(letter === "del") id = "delete-key";

        const guessed = keyboard.has(letter) ? "guessed-letter" : "";

        return (
            <div className={`keyboard-letter ${guessed}`} id={id} onClick={() => receiveInput(letter)} >
                <h1>{letter}</h1>
            </div>
        )
    }
}

export default KeyboardLetter;