import React from 'react';

class NavBar extends React.Component {
    render() {
        return (
            <div id="navbar">
                <div className="info-button" onClick={() => this.props.toggleSettings(true)}>
                    <img src="settings.svg" alt="settings" />
                </div>
                <h1>W🔥RDHELL</h1>
                <div className="info-button" onClick={() => this.props.toggleInstructions(true)}>
                    {/* <h1>ℹ︎</h1> */}
                    <img src="info.svg" alt="info" />
                </div>
            </div>
        )
    }
}

export default NavBar;