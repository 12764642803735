import React from 'react'
import GridRow from './GridRow';

class Grid extends React.Component {
    constructor(props) {
        super(props);

        this.generateGrid = this.generateGrid.bind(this);
    }

    generateGrid() {
        return this.props.grid.map((row, i) => (
            <GridRow key={`row-${i}`} row={row} />
            // row.map(cell => (
            //     <GridCell letter={cell[0]} status={cell[1]} />
            // ))
        ))
    }

    render() {
        return (
            <div id="grid">
                {this.generateGrid()}
            </div>
        );
    }
}

export default Grid;