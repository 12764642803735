import React from 'react';
import GridRow from './GridRow';

class Instructions extends React.Component {
    render() {
        const row1 = [['S','gray'],['H','green'],['O','yellow'],['C','gray'],['K','gray']];
        const row2 = [['🔥','hell'],['🔥','hell'],['O','yellow'],['C','gray'],['K','gray']];

        return (
            <div id="instructions-wrapper" onClick={() => this.props.toggleInstructions(false)} className={this.props.showInstructions ? "shown" : "hidden"}>
                <div id="instructions">
                    <div className="close-button">X</div>
                    <p>Welcome to WordHell.</p>
                    <p>Complete the puzzle in 6 or fewer attempts.</p>
                    <GridRow row={row1}/>
                    <p>Work quickly before previous guesses burn up.</p>
                    <GridRow row={row2}/>
                    <p>Gray letters burn faster than yellow and green letters.</p>
                    <p>Tap the <img src="settings.svg" height="20"/> to change the difficulty level</p>
                    <p onClick={e => e.stopPropagation()}>Made in Brooklyn, New York by Kenny Lozeau</p>
                </div>
            </div>
        )
    }
}

export default Instructions;