import React from 'react';

class GameOverMessage extends React.Component {
    render() {
        return (
            <div id="gameover-message" className={this.props.showMessage ? "shown" : "hidden"}>
                <h1>{this.props.message}</h1>
            </div>
        );
    }
}

export default GameOverMessage;