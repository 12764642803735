import React from 'react';
import KeyboardLetter from './KeyboardLetter';

class KeyboardRow extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    generateRow(letters) {
        const row = letters.split("").map(letter => (
            <KeyboardLetter key={letter} keyboard={this.props.keyboard} letter={letter} receiveInput={this.props.receiveInput}/>
        ))

        if(letters[0] === "Z") {
            row.unshift(<KeyboardLetter key={"ent"} keyboard={this.props.keyboard} letter={"enter"} receiveInput={this.props.receiveInput}/>)
            row.push(<KeyboardLetter key={"del"} keyboard={this.props.keyboard} letter={"del"} receiveInput={this.props.receiveInput}/>)
        }
        return row;
    }

    render() {
        const letters = this.props.letters;
        return (
            <>
                <div className="keyboard-row">{this.generateRow(letters)}</div>
            </>
        )
    }
}

export default KeyboardRow;