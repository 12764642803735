import React from 'react'
import GridCell from './GridCell';

class GridRow extends React.Component {
    constructor(props) {
        super(props);

        this.generateRow = this.generateRow.bind(this);
    }

    generateRow() {
        return this.props.row.map((cell, i) => (
            <GridCell key={i} letter={cell[0]} status={cell[1]} />
        ))
    }

    render() {
        return (
            <div className="grid-row">
                {this.generateRow()}
            </div>
        );
    }
}

export default GridRow;