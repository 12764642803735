import React from 'react';

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            difficulty: 'medium'
        };

        this.details = this.details.bind(this);
        this.changeDifficulty = this.changeDifficulty.bind(this);
    }

    componentDidMount() {
        const difficulty = this.checkAndGetCookie('difficulty');

        if(difficulty) {
            this.setState({difficulty});
        } else {
            this.setState({difficulty: 'medium'});
        }
    }

    checkAndGetCookie(key) {
        if(document.cookie.split(';').some(item => item.trim().startsWith(`${key}=`))) {
            const data = document.cookie.split('; ').find(row => row.startsWith(`${key}=`)).split('=')[1];
            if(key === 'prevGrid') {
            console.log(data)
            return JSON.parse(data);
            } else {
            return data;
            }
        } else {
            return null;
        }
    }

    details() {
        const details = {
            'easy': ["SLOWEST row burn time", "SLOWEST letter burn time"],
            'medium': ["FASTER row burn time", "FASTEST letter burn time"],
            'hard': ["FASTEST row burn time", "FASTEST letter burn time"],
            'hell': ["DON'T BLINK","GOOD LUCK"]
        };
        return (
            <>
                <p>{details[this.state.difficulty][0]}</p>
                <p>{details[this.state.difficulty][1]}</p>
            </>
        );
    }

    changeDifficulty(difficulty) {
        this.props.setDifficulty(difficulty);
        this.setState({difficulty});
    }

    render() {
        return (
            <div id="settings-wrapper" onClick={() => this.props.toggleSettings(false)} className={this.props.showSettings ? "shown" : "hidden"}>
                <div id="settings">
                    <div className="close-button">X</div>
                    <div id="difficulty-details">{this.details()}</div>
                    <div id="difficulty-options" onClick={e => e.stopPropagation()}>
                        <h1 className={this.state.difficulty === 'easy' ? 'selected' : ''} onClick={() => this.changeDifficulty('easy')}>EASY</h1>
                        <h1 className={this.state.difficulty === 'medium' ? 'selected' : ''} onClick={() => this.changeDifficulty('medium')}>MEDIUM</h1>
                        <h1 className={this.state.difficulty === 'hard' ? 'selected' : ''} onClick={() => this.changeDifficulty('hard')}>HARD</h1>
                        <h1 className={this.state.difficulty === 'hell' ? 'selected' : ''} onClick={() => this.changeDifficulty('hell')}>HELL</h1>
                    </div>
                </div>
            </div>
        )
    }
}

export default Settings;