import React from 'react';
import KeyboardRow from './KeyboardRow';
import throttle from '../util/throttle';

class Keyboard extends React.Component {
    constructor(props) {
        super(props);

        this.handleKeyPress = throttle(this.handleKeyPress.bind(this), 80);
    }

    componentDidMount() {
        document.addEventListener('keyup', this.handleKeyPress);
    }

    handleKeyPress(e) {
        console.log(e.code)
        const alphabet = "QWERTYUIOPASDFGHJKLZXCVBNM";
        const pressedKey = e.code;

        if(alphabet.includes(pressedKey.slice(-1))) {
            this.props.receiveInput(pressedKey.slice(-1))
        } else if(pressedKey.toLowerCase() === "enter") {
            this.props.receiveInput("enter")
        } else if(pressedKey.toLowerCase() === "backspace") {
            this.props.receiveInput("del")
        }
    }

    render() {
        return (
            <div id="keyboard">
                <KeyboardRow keyboard={this.props.keyboard} receiveInput={this.props.receiveInput} letters={"QWERTYUIOP"}/>
                <KeyboardRow keyboard={this.props.keyboard} receiveInput={this.props.receiveInput} letters={"ASDFGHJKL"}/>
                <KeyboardRow keyboard={this.props.keyboard} receiveInput={this.props.receiveInput} letters={"ZXCVBNM"}/>
            </div>
        )
    }
}

export default Keyboard;