import './App.css';
import { merge } from 'lodash';
import dictionary from './dictionary/dictionary';
import React from 'react';
import Keyboard from './components/Keyboard';
import NavBar from './components/NavBar';
import Grid from './components/Grid';
import Instructions from './components/Instructions';
import GameOverMessage from './components/GameOverMessage';
import Settings from './components/Settings';

class App extends React.Component {
  constructor(props) {
    super(props);

    let dayOne = new Date(2022, 5, 9);
    dayOne.setHours(0, 0, 0, 0);

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    const dictIdx = Math.floor((today - dayOne) / (24 * 3600000));

    this.state = {
      word: dictionary[dictIdx].toUpperCase(),
      grid: [
        [[' ','none'],[' ','none'],[' ','none'],[' ','none'],[' ','none']],
        [[' ','none'],[' ','none'],[' ','none'],[' ','none'],[' ','none']],
        [[' ','none'],[' ','none'],[' ','none'],[' ','none'],[' ','none']],
        [[' ','none'],[' ','none'],[' ','none'],[' ','none'],[' ','none']],
        [[' ','none'],[' ','none'],[' ','none'],[' ','none'],[' ','none']],
        [[' ','none'],[' ','none'],[' ','none'],[' ','none'],[' ','none']],
      ],
      currentRow: 0,
      currentCol: 0,
      hellQueue: [],
      currHellRow: null,
      keyboard: new Set(),
      winState: false,
      showInstructions: true,
      showSettings: false,
      showMessage: false,
      gameOverMessage: null,
      rowBurnTime: 10000,
      cellBurnTime: 1
    };

    this.receiveInput = this.receiveInput.bind(this);
    this.goToHell = this.goToHell.bind(this);
    this.checkGuess = this.checkGuess.bind(this);
    this.updateKeyboard = this.updateKeyboard.bind(this);
    this.checkGrid = this.checkGrid.bind(this);
    this.toggleInstructions = this.toggleInstructions.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.displayMessage = this.displayMessage.bind(this);
    this.setDifficulty = this.setDifficulty.bind(this);
  }

  componentDidMount() {
    const prevWord = this.checkAndGetCookie('prevWord');
    const difficulty = this.checkAndGetCookie('difficulty');

    if(difficulty) {
      this.setDifficulty(difficulty);
    } else {
      this.setDifficulty('medium');
    }

    if(prevWord !== this.state.word) {
      let midnight = new Date();
      midnight.setHours(24, 0, 0, 0);

      document.cookie = `prevWord=${this.state.word};expires=${midnight.toUTCString()}`;
      document.cookie = `winState=${this.state.winState};expires=${midnight.toUTCString()}`;
      document.cookie = `currentRow=${this.state.currentRow};expires=${midnight.toUTCString()}`;
      document.cookie = `prevGrid=${JSON.stringify(this.state.grid)};expires=${midnight.toUTCString()}`;
    } else {
      const prevGrid = this.encodeGrid(this.checkAndGetCookie('prevGrid'));
      const currentRow = parseInt(this.checkAndGetCookie('currentRow'));
      const winState = this.checkAndGetCookie('winState') === 'true' ? true : false;

      if(Array.isArray(prevGrid)) {
        this.setState({
          grid: prevGrid,
          currentRow,
          winState
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.hellQueue !== this.state.hellQueue && this.state.hellQueue.length > 0) {
      const hellQueue = merge([], this.state.hellQueue);
      const rowToHell = hellQueue[0];

      setTimeout(() => {
        if(!this.state.winState) {
          this.setState(prevState => ({
            currHellRow: rowToHell,
          }));
        }
      }, this.state.rowBurnTime); // ROW BURN TIME
    }

    if(prevState.currHellRow !== this.state.currHellRow) {
      const rowToHell = this.state.currHellRow;

      let delay = 0;

      for(let i = 0; i < 5; i++) {
        if(i === 4) {
          if(this.state.grid[rowToHell][i][1] === 'green') {
            delay += 3000;
          } else if(this.state.grid[rowToHell][i][1] === 'yellow') {
            delay += 2000;
          } else {
            delay += 1000;
          }
          setTimeout(() => {
            const newGrid = merge([], this.state.grid);
            const keyboard = new Set([...this.state.keyboard]);

            if(!this.checkGrid(newGrid[rowToHell][i][0], this.state.currHellRow)) {
              keyboard.delete(newGrid[rowToHell][i][0]);
            }

            newGrid[rowToHell][i] = ['🔥', 'hell'];
            document.cookie = `prevGrid=${JSON.stringify(this.decodeGrid(newGrid))}`;
            const hellQueue = merge([], this.state.hellQueue).slice(1);

            if(!this.state.winState) {
              this.setState(prevState => ({
                grid: newGrid,
                hellQueue,
                keyboard,
              }));
            }
          }, delay * this.state.cellBurnTime); // CELL BURN TIME
        } else {
          if(this.state.grid[rowToHell][i][1] === 'green') {
            delay += 3000;
          } else if(this.state.grid[rowToHell][i][1] === 'yellow') {
            delay += 2000;
          } else if(i === 0) {
            delay = 0;
          } else {
            delay += 1000;
          }
          setTimeout(() => {
            const newGrid = merge([], this.state.grid);
            const keyboard = new Set([...this.state.keyboard]);

            if(!this.checkGrid(newGrid[rowToHell][i][0], this.state.currHellRow)) {
              keyboard.delete(newGrid[rowToHell][i][0]);
            }

            newGrid[rowToHell][i] = ['🔥', 'hell'];
            document.cookie = `prevGrid=${JSON.stringify(this.decodeGrid(newGrid))}`;
            if(!this.state.winState) {
              this.setState(prevState => ({
                grid: newGrid,
                keyboard,
              }));
            }
          }, delay * this.state.cellBurnTime); // CELL BURN TIME
        }
      }
    }
  }

  checkDictionary(word) {
    return dictionary.includes(word.toLowerCase())
  }

  goToHell(row) {
    const hellQueue = merge([], this.state.hellQueue);
    hellQueue.push(row);
    this.setState({hellQueue});
  }

  checkAndGetCookie(key) {
    if(document.cookie.split(';').some(item => item.trim().startsWith(`${key}=`))) {
      const data = document.cookie.split('; ').find(row => row.startsWith(`${key}=`)).split('=')[1];
      if(key === 'prevGrid') {
        console.log(data)
        return JSON.parse(data);
      } else {
        return data;
      }
    } else {
      return null;
    }
  }

  decodeGrid(grid) {
    const newGrid = merge([], grid);

    for(let i = 0; i < grid.length; i++) {
      if(grid[i].every(cell => cell[1] === 'green')) break;

      if(grid[i][0][0] === '🔥' || "QWERTYUIOPASDFGHJKLZXCVBNM".includes(grid[i][0][0])) {
        for(let j = 0; j < grid[0].length; j++) {
          newGrid[i][j] = ['fire', 'hell']
        }
      }
    }

    return newGrid;
  }

  encodeGrid(grid) {
    if (grid === null) return null;

    const newGrid = merge([], grid);

    for(let i = 0; i < grid.length; i++) {
      for(let j = 0; j < grid[0].length; j++) {
        if(grid[i][j][0] === 'fire') newGrid[i][j][0] = '🔥'
      }
    }

    return newGrid;
  }

  checkGuess(row) {
    const guess = this.state.grid[row].map(cell => cell[0]);
    const word = this.state.word.split("");
    const newGrid = merge([], this.state.grid);
    
    for(let i = 0; i < 5; i++){
      if(guess[i] === this.state.word[i]) {
        newGrid[row][i] = [guess[i], 'green'];
        word[i] = " ";
        this.setState({grid: newGrid});
      }
    }
    
    for(let i = 0; i < 5; i++) {
      if(word.includes(guess[i]) && newGrid[row][i][1] !== 'green') {
        newGrid[row][i] = [guess[i], 'yellow'];
        const idx = word.indexOf(guess[i]);
        word[idx] = " ";
        this.setState({grid: newGrid});
      } else {
        if(newGrid[row][i][1] === 'green') {
          newGrid[row][i] = [guess[i], 'green'];
        } else {
          newGrid[row][i] = [guess[i], 'gray'];
        }
        this.setState({grid: newGrid});
      }
    }

    let greenCount = 0;
    newGrid[row].forEach(cell => {
      if(cell[1] === 'green') greenCount++;
    });
    if(greenCount === 5) {
      document.cookie = `prevGrid=${JSON.stringify(this.decodeGrid(newGrid))}`;
      this.displayMessage(true);
      document.cookie = `winState=true`;
      this.setState({winState: true});
    } else if(row === 5) {
      document.cookie = `prevGrid=${JSON.stringify(this.decodeGrid(newGrid))}`;
      this.displayMessage(false);
    }

    document.cookie = `prevGrid=${JSON.stringify(this.decodeGrid(newGrid))}`;
    document.cookie = `currentRow=${this.state.currentRow + 1}`;
  
  }

  receiveInput(letter) {
    const newGrid = merge([], this.state.grid);
    const row = this.state.currentRow;
    const col = this.state.currentCol;

    if(this.state.winState) return;

    if(letter === 'del') {
      if(col === 5) {
        newGrid[row][col - 1] = [' ', 'none'];
      } else {
        newGrid[row][col - 1] = [' ', 'none'];
      }
      this.setState(prevState => {
        return ({
          grid: newGrid,
          currentCol: col === 0 ? 0 : prevState.currentCol - 1
        });
      });
    } else if(letter === 'enter' && col === 5 && newGrid[row][col - 1] !== ' '){
      const guess = this.state.grid[row].map(cell => cell[0]).join('');

      if(this.checkDictionary(guess)) {
        this.checkGuess(row);
        this.updateKeyboard(row);
        this.goToHell(row);

        this.setState(prevState => ({
          currentCol: 0,
          currentRow: prevState.currentRow + 1,
        }));
      } else {
        this.setState({
          showMessage: true,
          gameOverMessage: "Try another word"
        });

        setTimeout(() => {
          this.setState({
            showMessage: false,
            gameOverMessage: null
          });
        }, 2000);
      }
    } else if(letter !== 'enter' && col < 5) {
        newGrid[row][col] = [letter, 'none'];
        this.setState(prevState => ({
          grid: newGrid,
          currentCol: prevState.currentCol + 1,
        }));
    }
  }

  updateKeyboard(row) {
    const keyboard = new Set([...this.state.keyboard]);
    const letters = this.state.grid[row].map(cell => cell[0]);
    letters.forEach(letter => keyboard.add(letter));

    this.setState({keyboard});
  }

  checkGrid(letter, currHellRow) {
    let found = false;

    for(let row = currHellRow + 1; row < 5; row++) {
      for(let col = 0; col < 4; col++) {
        if(this.state.grid[row][col][0] === letter) found = true;
      }
    }

    return found;
  }

  toggleInstructions(showInstructions) {
    this.setState({showInstructions});
  }

  toggleSettings(showSettings) {
    this.setState({showSettings});
  }

  displayMessage(winState) {
    let messages = [
      "UNBELIEVABLE",
      "AMAZING",
      "GREAT",
      "NICE",
      "YIKES",
      "WHOA",
    ];
    
    let delay = 3000;

    if(winState) {
      this.setState(prevState => ({
        showMessage: true,
        gameOverMessage: messages[prevState.currentRow]
      }));
    } else {
      delay = 5000;
      this.setState(prevState => ({
        showMessage: true,
        gameOverMessage: prevState.word
      }));
    }

    setTimeout(() => {
      this.setState({
        showMessage: false,
        gameOverMessage: null
      });
    }, delay);
  }

  setDifficulty(difficulty) {
    const delays = {
      'easy': [10000, 2],
      'medium': [5000, 1],
      'hard': [2000, 1],
      'hell': [1000, 0.01],
    };

    let midnight = new Date();
    midnight.setHours(24, 0, 0, 0);

    document.cookie = `difficulty=${difficulty};expires=${midnight.toUTCString()}`;

    this.setState({
      rowBurnTime: delays[difficulty][0],
      cellBurnTime: delays[difficulty][1]
    });
  }

  render() {
    return (
      <>
        <Instructions toggleInstructions={this.toggleInstructions} showInstructions={this.state.showInstructions} />
        <Settings toggleSettings={this.toggleSettings} showSettings={this.state.showSettings} setDifficulty={this.setDifficulty}/>
        <NavBar toggleInstructions={this.toggleInstructions} toggleSettings={this.toggleSettings} />
        <div id="board">
          <GameOverMessage message={this.state.gameOverMessage} showMessage={this.state.showMessage} />
          <div id="grid-container">
            <Grid grid={this.state.grid} />
          </div>
          <Keyboard receiveInput={this.receiveInput} keyboard={this.state.keyboard}  />
        </div>
      </>
    )
  }
}

export default App;
